import SplitType from 'split-type';
import { wrapLines } from './utils';

class TextLinesReveal {
    constructor(animationElems, gsap) {
        this.gsap = gsap;
        this.DOM = {
            animationElems: Array.isArray(animationElems)
                ? animationElems
                : [animationElems],
        };
        // array of SplitType instances
        this.SplitTypeInstances = [];
        // array of all HTML .line
        this.lines = [];

        for (const el of this.DOM.animationElems) {
            const SplitTypeInstance = new SplitType(el, { types: 'lines' });
            // wrap the lines (div with class .oh)
            // the inner child will be the one animating the transform
            wrapLines(SplitTypeInstance.lines, 'div', 'oh');
            this.lines.push(SplitTypeInstance.lines);
            // keep a reference to the SplitType instance
            this.SplitTypeInstances.push(SplitTypeInstance);
        }

        this.initEvents();
    }
    in() {
        this.isVisible = true;
    
        // Flatten lines array
        const flattenedLines = this.lines.flat();
    
        // Kill previous tweens
        this.gsap.killTweensOf(flattenedLines);
        
        return this.gsap.timeline({ defaults: { duration: 1.5, ease: 'expo' } })
            .set(flattenedLines, {
                y: '150%',
                rotate: 5,
            })
            .to(flattenedLines, {
                y: '0%',
                rotate: 0,
                stagger: 0.04,
            });
    }
    
    out() {
        this.isVisible = false;
    
        const flattenedLines = this.lines.flat();
        this.gsap.killTweensOf(flattenedLines);
        
        return this.gsap.timeline({ defaults: { duration: 0.7, ease: 'power2' } })
            .to(flattenedLines, {
                y: '-150%',
                rotate: -3,
                stagger: 0.1,
            });
    }
    initEvents() {
        let resizeTimeout;
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                this.lines = [];
                for (const instance of this.SplitTypeInstances) {
                    instance.split();
                    wrapLines(instance.lines, 'div', 'oh');
                    this.lines.push(instance.lines);
                }
                if (!this.isVisible) {
                    this.gsap.set(this.lines.flat(), { y: '-150%' });
                }
            }, 300);  // Debounce delay of 300ms
        });
    }
}

export const initTexts = (gsap) => {
    return new TextLinesReveal(
        [...document.querySelectorAll('h1:not(.no-anim), h2:not(.no-anim), h3:not(.no-anim), p:not(.no-anim)')],
        gsap,
    );
};
