import MouseFollower from 'mouse-follower';

export const initMouseCursor = (gsap) => {
    MouseFollower.registerGSAP(gsap);

    const cursor = new MouseFollower({
        skewing: 5,
    });

    const doc = document.documentElement;

    cursor.on('render', ({ pos, skew }) => {
        let x = parseInt((pos.x / window.innerWidth) * 100);
        let y = parseInt((pos.y / window.innerHeight) * 100);

        doc.style.setProperty('--mouse-x', `${x}%`);
        doc.style.setProperty('--mouse-y', `${y}%`);
    });
};
