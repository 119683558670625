import imagesLoaded from 'imagesloaded';

export const preloadAssets = (selector) => {
    return new Promise((resolve) => {
        imagesLoaded(
            document.querySelectorAll(selector),
            { background: true },
            resolve,
        );
    });
};

export const wrapLines = (elems, wrapType, wrapClass) => {
    elems.forEach((char) => {
        // add a wrap for every char (overflow hidden)
        const wrapEl = document.createElement(wrapType);
        wrapEl.classList = wrapClass;
        char.parentNode.appendChild(wrapEl);
        wrapEl.appendChild(char);
    });
};
