import { initGSAP } from './gsap';
import { initMenu } from './menu';
import { initCTA } from './cta';
import { initMouseCursor } from './mouse';
import { initSmoothScrolling } from './scroll';
import { initTexts } from './typo';
import { preloadAssets } from './utils';

preloadAssets('img, video').then(() => {
    // let menuOpen = false;
    const gsap = initGSAP();
    const expertiseImages = [...document.querySelectorAll('.expertise__image')];
    initSmoothScrolling(({ scroll, limit, velocity, direction, progress }) => {
        // const hovered = [...document.querySelectorAll(':hover')].pop();
        gsap.set(expertiseImages, {
            y: progress * -400,
        });
        if (progress > 0.01) {
            document.body.classList.add('layout--scrolled');
        } else {
            document.body.classList.remove('layout--scrolled');
        }
    });
    initMouseCursor(gsap);
    initMenu();
    initCTA();
    document.body.classList.remove('layout--loading');
    const textCtrl = initTexts(gsap);
    textCtrl.in();
});
